<template>
  <div>
    <el-steps :active="activeStep" finish-status="success" style="margin: 20px">
      <el-step :title="$t('scene.step', [1])" :description="$t('scene.step-one-desc')"></el-step>
      <el-step :title="$t('scene.step', [2])" :description="$t('scene.step-two-desc')"></el-step>
      <el-step :title="$t('scene.step', [3])" :description="$t('scene.step-three-desc')"></el-step>
      <el-step :title="$t('scene.step', [4])" :description="$t('scene.step-four-desc')"></el-step>
    </el-steps>


    <keep-alive>
      <component :is="steps[activeStep]" @prevStep="prevStep" @nextStep="nextStep"></component>
    </keep-alive>

    <!--<el-form :model="sceneData" ref="accessoryForm" class="form-container" label-position="left" label-width="220px" status-icon>

    </el-form>-->
  </div>
</template>

<script>
  import SceneInfoForm from './components/SceneInfoForm';
  import NewActionForm from './components/NewActionForm';
  import ActionEditForm from './components/ActionEditForm';
  import SceneDetailForm from './components/SceneDetailForm';

  export default {
    name: "NewScene",
    components: {
      sceneInfoForm: SceneInfoForm,
      newActionForm: NewActionForm,
      actionEditForm: ActionEditForm,
      sceneDetailForm: SceneDetailForm,
    },

    data() {
      return {
        activeStep: 0,
        loading: false,
        steps: ["sceneInfoForm", "newActionForm", "actionEditForm", "sceneDetailForm"],
      }
    },

    methods: {
      prevStep() {
        if (this.activeStep > 0)
          this.activeStep--
      },

      nextStep(step){
        console.log("steppp", step)
        if (step) {
          this.activeStep = step
        } else if (this.activeStep < this.steps.length - 1)
          this.activeStep++
        else {
          this.activeStep = 0
        }
      }
    },

  }
</script>

<style scoped>

</style>